<template src="./EnterTheEventModal.html">
</template>

<script>
export default {
  name: 'enter-the-event-modal',
  data () {
    return {
      eventCode: null
    }
  },
  props: {
    openEnterTheEventModal: { type: Boolean, defaul: false },
    image: { type: String, default: '' }
  },
  methods: {
    closeModal() {
      this.$emit('closeEnterTheEventModal')
    }
  },
  computed: {
    disableButton() {
      return this.eventCode === null || this.eventCode.length < 1;
    }
  }
}
</script>

<style lang="scss" scoped src="./EnterTheEventModal.scss">
</style>